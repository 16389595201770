var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',[_c('Breadcrumbs',{attrs:{"items":_vm.items}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{staticClass:"multi-col-validation"},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.formTitulo))]),_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id,on:{"click":function($event){_vm.keyComponnet += 1}}},[(tab.error)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(tab.icons)+" ")]):_vm._e(),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(tab.title))])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"eager":""}},[_c('v-row',{staticClass:"pa-3 mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre","outlined":"","dense":"","placeholder":"Nombre","error-count":"2","rules":[
                    function (v) { return !!v || 'El campo Nombre es obligatorio'; },
                    function (v) { return (!!v && v.length <= 50) || 'El campo Nombre hasta 50 caracteres'; } ],"counter":"50","maxlength":"50","error":_vm.campos.firstName.error,"error-messages":_vm.errorMsgFirstName},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Nombre","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.firstName),callback:function ($$v) {_vm.$set(_vm.obj, "firstName", $$v)},expression:"obj.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Apellidos","outlined":"","dense":"","placeholder":"Apellidos","error-count":"2","rules":[
                    function (v) { return !!v || 'El campo Apellido es obligatorio'; },
                    function (v) { return (!!v && v.length <= 50) || 'El campo Apellido hasta 50 caracteres'; } ],"counter":"50","maxlength":"50","error":_vm.campos.lastName.error,"error-messages":_vm.errorMsgLastName},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Apellidos","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.lastName),callback:function ($$v) {_vm.$set(_vm.obj, "lastName", $$v)},expression:"obj.lastName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Usuario","outlined":"","dense":"","placeholder":"Usuario","error-count":"2","rules":[
                    function (v) { return !!v || 'El campo Usuario es obligatorio'; },
                    function (v) { return (!!v && v.length <= 50) || 'El campo Usuario hasta 50 caracteres'; } ],"counter":"50","maxlength":"50","error":_vm.campos.userName.error,"error-messages":_vm.errorMsgUserName},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Usuario","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.userName),callback:function ($$v) {_vm.$set(_vm.obj, "userName", $$v)},expression:"obj.userName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Teléfono","outlined":"","dense":"","placeholder":"(09) 99999999","counter":"50","maxlength":"50","error-count":"2","error":_vm.campos.phone.error,"error-messages":_vm.errorMsgPhone},model:{value:(_vm.obj.phone),callback:function ($$v) {_vm.$set(_vm.obj, "phone", $$v)},expression:"obj.phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Correo","outlined":"","dense":"","placeholder":"Correo","suffix":_vm.emailSufix,"rules":[
                    function (v) { return !!v || 'El campo Correo es obligatorio'; },
                    function (v) { return _vm.isValidEmail(v) || 'Formato Correo es incorrecto'; } ],"error-count":"2","error":_vm.campos.email.error,"error-messages":_vm.errorMsgEmail},model:{value:(_vm.obj.email),callback:function ($$v) {_vm.$set(_vm.obj, "email", $$v)},expression:"obj.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.status,"item-value":"id","item-text":"name","label":"Estado","dense":"","outlined":""},model:{value:(_vm.obj.status),callback:function ($$v) {_vm.$set(_vm.obj, "status", $$v)},expression:"obj.status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{ref:"refArea",attrs:{"items":_vm.listaAreas,"loading":_vm.loadingAreas,"item-value":"id","item-text":"name","label":"Área","dense":"","outlined":"","return-object":"","error":_vm.campos.area.error,"error-messages":_vm.errorMsgArea,"rules":[
                    function (v) { return !!v || 'El campo Área es obligatorio'; } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Área","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.area),callback:function ($$v) {_vm.$set(_vm.obj, "area", $$v)},expression:"obj.area"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('DualList',{attrs:{"items":_vm.listaProfiles,"item-text":"name","item-value":"id","mobile-breakpoint":_vm.mobileBreakpoint,"messages":_vm.messages,"icons":_vm.icons},model:{value:(_vm.obj.profiles),callback:function ($$v) {_vm.$set(_vm.obj, "profiles", $$v)},expression:"obj.profiles"}})],1)],1)],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-row',{staticClass:"pa-3 mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('tablaComponent',{ref:"tabla",attrs:{"headers":_vm.headers,"titulo-tabla":_vm.tituloTabla,"url":_vm.url,"cargando":_vm.cargando,"search":_vm.search,"have-pagination":_vm.havePagination,"have-actions":true,"have-detail":_vm.haveDetail,"have-search":_vm.haveSearch},on:{"dialogSearch":function($event){return _vm.cargarSearch()},"refreshSearch":function($event){return _vm.refreshSearch()},"detailitem":function($event){return _vm.detailItem($event)}},scopedSlots:_vm._u([{key:"item.initialValue",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.initialValue)+" ")])]}},{key:"item.finalValue",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.finalValue)+" ")])]}}],null,true)})],1)],1)],1)],1),(true)?_c('v-card-actions',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingSaveForm},on:{"click":_vm.saveItem}},[_vm._v(" Enviar ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"type":"reset","outlined":""},on:{"click":_vm.cancelarForm}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('detallelogs_component',{attrs:{"dialog_log":_vm.dialog_log,"id":_vm.id_log},on:{"close_dialog":function($event){return _vm.close_dialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }