<template>
  <v-row class="match-height">
    <v-col>
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-form class="multi-col-validation">
        <v-card>
          <v-card-title>{{ formTitulo }}</v-card-title>
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.id"
              @click="keyComponnet += 1"
            >
              <v-icon
                v-if="tab.error"
                color="error"
              >
                {{ tab.icons }}
              </v-icon>
              <span class="mx-2">{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item eager>
              <v-row class="pa-3 mt-2">
                <!-- firstName -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.firstName"
                    label="Nombre"
                    outlined
                    dense
                    placeholder="Nombre"
                    error-count="2"
                    :rules="[
                      (v) => !!v || 'El campo Nombre es obligatorio',
                      (v) => (!!v && v.length <= 50) || 'El campo Nombre hasta 50 caracteres',
                    ]"
                    counter="50"
                    maxlength="50"
                    :error="campos.firstName.error"
                    :error-messages="errorMsgFirstName"
                  >
                    <template #label>
                      <app-label
                        field="Nombre"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- lastName -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.lastName"
                    label="Apellidos"
                    outlined
                    dense
                    placeholder="Apellidos"
                    error-count="2"
                    :rules="[
                      (v) => !!v || 'El campo Apellido es obligatorio',
                      (v) => (!!v && v.length <= 50) || 'El campo Apellido hasta 50 caracteres',
                    ]"
                    counter="50"
                    maxlength="50"
                    :error="campos.lastName.error"
                    :error-messages="errorMsgLastName"
                  >
                    <template #label>
                      <app-label
                        field="Apellidos"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- userName -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.userName"
                    label="Usuario"
                    outlined
                    dense
                    placeholder="Usuario"
                    error-count="2"
                    :rules="[
                      (v) => !!v || 'El campo Usuario es obligatorio',
                      (v) => (!!v && v.length <= 50) || 'El campo Usuario hasta 50 caracteres',
                    ]"
                    counter="50"
                    maxlength="50"
                    :error="campos.userName.error"
                    :error-messages="errorMsgUserName"
                  >
                    <template #label>
                      <app-label
                        field="Usuario"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- phone -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.phone"
                    label="Teléfono"
                    outlined
                    dense
                    placeholder="(09) 99999999"
                    counter="50"
                    maxlength="50"
                    error-count="2"
                    :error="campos.phone.error"
                    :error-messages="errorMsgPhone"
                  ></v-text-field>
                </v-col>

                <!-- email -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.email"
                    label="Correo"
                    outlined
                    dense
                    placeholder="Correo"
                    :suffix="emailSufix"
                    :rules="[
                      (v) => !!v || 'El campo Correo es obligatorio',
                      (v) => isValidEmail(v) || 'Formato Correo es incorrecto',
                    ]"
                    error-count="2"
                    :error="campos.email.error"
                    :error-messages="errorMsgEmail"
                  ></v-text-field>
                </v-col>

                <!-- Estado -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="obj.status"
                    :items="status"
                    item-value="id"
                    item-text="name"
                    label="Estado"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <!-- Area -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    ref="refArea"
                    v-model="obj.area"
                    :items="listaAreas"
                    :loading="loadingAreas"
                    item-value="id"
                    item-text="name"
                    label="Área"
                    dense
                    outlined
                    return-object
                    :error="campos.area.error"
                    :error-messages="errorMsgArea"
                    :rules="[
                      (v) => !!v || 'El campo Área es obligatorio',
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Área"
                        required="true"
                      />
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  class="mt-5"
                  cols="12"
                  md="12"
                >
                  <DualList
                    v-model="obj.profiles"
                    :items="listaProfiles"
                    item-text="name"
                    item-value="id"
                    :mobile-breakpoint="mobileBreakpoint"
                    :messages="messages"
                    :icons="icons"
                  ></DualList>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- Los del Sistema -->
            <v-tab-item eager>
              <v-row class="pa-3 mt-2">
                <v-col cols="12">
                  <tablaComponent
                    ref="tabla"
                    :headers="headers"
                    :titulo-tabla="tituloTabla"
                    :url="url"
                    :cargando="cargando"
                    :search="search"
                    :have-pagination="havePagination"
                    :have-actions="true"
                    :have-detail="haveDetail"
                    :have-search="haveSearch"
                    @dialogSearch="cargarSearch()"
                    @refreshSearch="refreshSearch()"
                    @detailitem="detailItem($event)"
                  >
                    <template v-slot:[`item.initialValue`]="{ item }">
                      <div class="truncate">
                        {{ item.initialValue }}
                      </div>
                    </template>
                    <template v-slot:[`item.finalValue`]="{ item }">
                      <div class="truncate">
                        {{ item.finalValue }}
                      </div>
                    </template>
                  </tablaComponent>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions
            v-if="true"
            class="mt-5"
          >
            <v-row>
              <!-- Botones de acción -->
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="loadingSaveForm"
                  @click="saveItem"
                >
                  Enviar
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <detallelogs_component
      :dialog_log="dialog_log"
      :id="id_log"
      @close_dialog="close_dialog()"
    />
  </v-row>
</template>

<script>
import {
  mdiCalendar,
  mdiChevronDoubleDown,
  mdiChevronDown,
  mdiChevronDoubleUp,
  mdiChevronUp,
  mdiChevronDoubleRight,
  mdiChevronRight,
  mdiChevronDoubleLeft,
  mdiChevronLeft,
  mdiAlertOctagonOutline,
} from '@mdi/js'
import moment from 'moment'
import { ref, reactive } from '@vue/composition-api'
import { useRouter } from '@/utils'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import DualList from '@/components/DualList/DualList.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import tablaComponent from '@/components/tablaComponent.vue'
import detallelogs_component from '@/views/systemlog/detallelogs_component.vue'

export default {
  components: {
    DualList,
    AppLabel,
    Breadcrumbs,
    tablaComponent,
    detallelogs_component,
  },
  data() {
    return {
      itemId: '',
      imageUrlDefault: require('@/assets/images/default-img.png'),
      formTitulo: 'Editar Funcionario Municipal',
      loadingSaveForm: false,
      loadingProfiles: false,
      listaProfiles: [],
      loadingAreas: false,
      listaAreas: [],
      searchForm: true,
      height: 500,
      color: '#FFA500',
      menu: false,
      menu2: false,
      menu3: false,
      emailSufix: '@guayaquil.gov.ec',
      items: reactive([
        {
          text: 'Inicio',
          disabled: false,
          href: '/',
        },
        {
          text: 'Funcionarios Municipales',
          disabled: false,
          href: '/usuarios-miembros',
        },
        {
          text: 'Editar Funcionario Municipal',
          disabled: true,
          href: '/usuarios-miembros/cambiar-estado-usuarios-miembros',
        },
      ]),
      keyComponnet: 0,
      tab: '',
      tabs: [
        {
          id: '1', title: 'Datos Generales', icons: mdiAlertOctagonOutline, error: false,
        },
        {
          id: '2', title: 'Logs del Sistema', icons: mdiAlertOctagonOutline, error: false,
        },
      ],

      // Logs del Sistema
      tituloTabla: 'Log del Sistema',
      headers: [
        {
          text: 'Nombre', sortable: true, value: 'name', width: '200',
        },
        { text: 'Usuario', sortable: true, value: 'userName' },
        { text: 'Tipo de Operación', sortable: true, value: 'operationType' },
        { text: 'Fecha', sortable: true, value: 'createdAt' },
        {
          text: 'Acción', value: 'actions', align: 'center', width: '180', sortable: false,
        },

        /* {
          text: 'Valor Inicial', sortable: true, value: 'initialValue', width: 50,
        },
        {
          text: 'Valor Final', sortable: true, value: 'finalValue', width: 50,
        }, */
      ],
      url: `${HTTP.defaults.baseURL}identity-api/SystemLogs`,
      cargando: false,
      search: { ObjectId: this.itemId },
      havePagination: true,
      haveSearch: false,
      haveDetail: true,
      dialog_log: false,
      id_log: '',
      close_dialog: () => {
        this.dialog_log = false
      },
      detailItem: item => {
        this.id_log = item.systemLogId
        this.dialog_log = true
        // this.$router.push(
        //   {
        //     name: 'details_log_sistema',
        //     params: {
        //       id: item.systemLogId,
        //       formTitulo: this.formTitulo,
        //       cancelarForm: 'usuarios_miembros',
        //       items: this.items,
        //     },
        //   },
        // )
      },
      obj: {
        userName: '',
        firstName: '',
        lastName: '',
        identificationType: null,
        identification: '',
        email: '',
        phone: '',
        location: '',
        status: null,
        gender: null,
        dateOfBirth: '',
        profiles: [],
        areaId: '',
        area: null,
      },
      tiposIdentificacion: [
        { id: 'Ruc', name: 'Ruc' },
        { id: 'CI', name: 'CI' },
        { id: 'Passport', name: 'Pasaporte' },
      ],
      genero: [
        { id: 'Male', name: 'Masculino' },
        { id: 'Female', name: 'Femenino' },
      ],
      estadoCivil: [
        { id: 'Single', name: 'Soltero' },
        { id: 'Married', name: 'Casado' },
        { id: 'Divorced', name: 'Divorciado' },
      ],
      status: [
        { id: 'active', name: 'Activo' },
        { id: 'inactive', name: 'Inactivo' },
        { id: 'deleted', name: 'Eliminado' },
      ],
      items3: [
        { text: 'Item 1', value: 1 },
        { text: 'Item 2', value: 2 },
        { text: 'Item 3', value: 3 },
      ],
      selected: [],
      mobileBreakpoint: 960,
      dualListaltura: 'auto',
      messages: {
        listOptions: 'Perfiles',
        listSelected: 'Perfiles seleccionados',
        forwardAll: 'Pasar todos los elementos',
        forward: 'Pasar el elemento seleccionado',
        backwardAll: 'Regresar todos los elementos seleccionados',
        backward: 'Regresar el elemento seleccionado',
        find: 'Buscar',
      },
      icon: {
        mdiCalendar,
      },
      icons: {
        mobile: {
          forwardAll: mdiChevronDoubleDown,
          forward: mdiChevronDown,
          backwardAll: mdiChevronDoubleUp,
          backward: mdiChevronUp,
        },
        desktop: {
          forwardAll: mdiChevronDoubleRight,
          forward: mdiChevronRight,
          backwardAll: mdiChevronDoubleLeft,
          backward: mdiChevronLeft,
        },
      },
      campos: {
        userName: {
          error: false,
          mensajesError: [],
        },
        firstName: {
          error: false,
          mensajesError: [],
        },
        lastName: {
          error: false,
          mensajesError: [],
        },
        identificationType: {
          error: false,
          mensajesError: [],
        },
        identification: {
          error: false,
          mensajesError: [],
        },
        email: {
          error: false,
          mensajesError: [],
        },
        phone: {
          error: false,
          mensajesError: [],
        },
        location: {
          error: false,
          mensajesError: [],
        },
        status: {
          error: false,
          mensajesError: [],
        },
        gender: {
          error: false,
          mensajesError: [],
        },
        dateOfBirth: {
          error: false,
          mensajesError: [],
        },
        phone: {
          error: false,
          mensajesError: [],
        },
        maritalStatus: {
          error: false,
          mensajesError: [],
        },
        areaId: {
          error: false,
          mensajesError: [],
        },
        area: {
          error: false,
          mensajesError: [],
        },
      },
      notificaciones: {
        snackbar: false,
        color: '',
        mode: '',
        text: '',
        timeout: 3000,
        x: null,
        y: 'top',
      },
    }

    // Errores
  },
  computed: {
    errorMsgUserName() {
      return this.campos.userName.error ? this.campos.userName.mensajesError : []
    },
    errorMsgFirstName() {
      return this.campos.firstName.error ? this.campos.firstName.mensajesError : []
    },
    errorMsgLastName() {
      return this.campos.lastName.error ? this.campos.lastName.mensajesError : []
    },
    errorMsgIdentificationType() {
      return this.campos.identificationType.error ? this.campos.identificationType.mensajesError : []
    },
    errorMsgIdentification() {
      return this.campos.identification.error ? this.campos.identification.mensajesError : []
    },
    errorMsgEmail() {
      return this.campos.email.error ? this.campos.email.mensajesError : []
    },
    errorMsgPhone() {
      return this.campos.phone.error ? this.campos.phone.mensajesError : []
    },
    errorMsgLocation() {
      return this.campos.location.error ? this.campos.location.mensajesError : []
    },
    errorMsgStatus() {
      return this.campos.status.error ? this.campos.status.mensajesError : []
    },
    errorMsgGender() {
      return this.campos.gender.error ? this.campos.gender.mensajesError : []
    },
    errorMsgDateOfBirth() {
      return this.campos.dateOfBirth.error ? this.campos.dateOfBirth.mensajesError : []
    },
    errorMsgMaritalStatus() {
      return this.campos.maritalStatus.error ? this.campos.maritalStatus.mensajesError : []
    },
    errorMsgArea() {
      return this.campos.areaId.error ? this.campos.areaId.mensajesError : []
    },
  },
  created() {
    this.itemId = this.$route.params.id
    this.search = { ObjectId: this.itemId }
  },
  mounted() {
    this.getItemById(this.itemId)

    // this.getAreas()
  },

  methods: {
    isValidName(s) {
      if (s === '') return true
      const regexp = /^[A-Z]+([\\ ]{0,1}[A-Za-z]+)*$/

      return regexp.test(s)
    },
    isValidEmail(s) {
      if (s === '') return true
      const regexp = /^[a-z]+([\\_\\-]{0,1}[a-z0-9]+)*$/

      return regexp.test(s)
    },
    async getProfiles() {
      this.loadingProfiles = true
      const URL = `${HTTP.defaults.baseURL}identity-api/profile?PageSize=100`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.entities.length; index += 1) {
          this.listaProfiles.push(resp.data.entities[index])
        }
        this.loadingProfiles = false
      }
    },
    async getItemById(id) {
      try {
        if (id) {
          await this.getProfiles()
          const URL = `${HTTP.defaults.baseURL}identity-api/manager/${id}`
          const resp = await HTTP.get(URL)
          if (resp.status === 200) {
            if (resp.data) {
              // obj.
              this.obj.userName = resp.data.username
              this.obj.firstName = resp.data.firstName
              this.obj.lastName = resp.data.lastName
              this.obj.identificationType = resp.data.identificationType
              this.obj.identification = resp.data.identification
              if (resp.data.email) {
                this.obj.email = resp.data.email.split('@')[0]
              }
              this.obj.location = resp.data.location
              this.obj.status = resp.data.status
              this.obj.gender = resp.data.gender
              this.obj.dateOfBirth = moment(resp.data.dateOfBirth).format('YYYY-MM-DD')
              this.obj.phone = resp.data.phone
              this.obj.profiles = resp.data.profiles
              this.obj.areaId = resp.data.areaId

              this.setArea()
            }
          }
        }
      } catch (error) {

      }
    },
    cancelarForm() {
      this.$router.push({ name: 'usuarios_miembros' })
    },

    async getAreas() {
      this.loadingAreas = true
      const URL = `${HTTP.defaults.baseURL}identity-api/areas/all`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.value.length; index += 1) {
          this.listaAreas.push(resp.data.value[index])
        }

        // Ordenar alfabeticamente
        this.listaAreas.sort((a, b) => {
          if (a.name === b.name) {
            return 0
          }
          if (a.name < b.name) {
            return -1
          }

          return 1
        })
        this.loadingAreas = false
      }
    },

    async setArea() {
      await this.getAreas()
      for (let index = 0; index < this.listaAreas.length; index += 1) {
        if (this.obj.areaId === this.listaAreas[index].id) {
          this.obj.area = this.listaAreas[index]
        }
      }
    },

    async saveItem() {
      this.loadingSaveForm = true
      const arrayPermisos = []
      for (let i = 0; i < this.obj.profiles.length; i += 1) {
        arrayPermisos.push(this.obj.profiles[i].id)
      }
      const objToSave = {
        // obj
        username: this.obj.userName,
        firstName: this.obj.firstName,
        lastName: this.obj.lastName,
        identificationType: this.obj.identificationType,
        identification: this.obj.identification,
        email: `${this.obj.email + this.emailSufix}`,
        phone: this.obj.phone,
        location: this.obj.location,
        status: this.obj.status,
        gender: this.obj.gender,
        dateOfBirth: this.obj.dateOfBirth,
        maritalStatus: this.obj.maritalStatus,
        profileIds: arrayPermisos,
        areaId: this.obj.area.id,
      }
      try {
        const URL = `${HTTP.defaults.baseURL}identity-api/manager/${this.itemId}`
        const resp = await HTTP.put(URL, objToSave)
        if (resp.status === 200) {
          this.loadingSaveForm = false
          this.notificaciones.snackbar = true
          this.notificaciones.color = 'success'
          this.notificaciones.text = 'El registro ha sido actualizado satisfactoriamente'
          this.$router.push({
            name: 'usuarios_miembros',
            params: {
              notificaciones: {
                snackbar: this.notificaciones.snackbar,
                text: this.notificaciones.text,
                color: this.notificaciones.color,
              },
            },
          })
        }
      } catch (error) {
        if (error.response.data.errors.UserName) {
          for (let index = 0; index < error.response.data.errors.UserName.length; index += 1) {
            this.campos.userName.mensajesError.push(error.response.data.errors.UserName[index])
          }
          this.campos.userName.error = true
        }
        if (error.response.data.errors.FirstName) {
          for (let index = 0; index < error.response.data.errors.FirstName.length; index += 1) {
            this.campos.firstName.mensajesError.push(error.response.data.errors.FirstName[index])
          }
          this.campos.firstName.error = true
        }
        if (error.response.data.errors.LastName) {
          for (let index = 0; index < error.response.data.errors.LastName.length; index += 1) {
            this.campos.lastName.mensajesError.push(error.response.data.errors.LastName[index])
          }
          this.campos.lastName.error = true
        }
        if (error.response.data.errors.IdentificationType) {
          for (let index = 0; index < error.response.data.errors.IdentificationType.length; index += 1) {
            this.campos.identificationType.mensajesError.push(error.response.data.errors.IdentificationType[index])
          }
          this.campos.identificationType.error = true
        }
        if (error.response.data.errors.Identification) {
          for (let index = 0; index < error.response.data.errors.Identification.length; index += 1) {
            this.campos.identification.mensajesError.push(error.response.data.errors.Identification[index])
          }
          this.campos.identification.error = true
        }
        if (error.response.data.errors.Email) {
          for (let index = 0; index < error.response.data.errors.Email.length; index += 1) {
            this.campos.imageUrl.email.push(error.response.data.errors.Email[index])
          }
          this.campos.email.error = true
        }
        if (error.response.data.errors.Phone) {
          for (let index = 0; index < error.response.data.errors.Phone.length; index += 1) {
            this.campos.phone.mensajesError.push(error.response.data.errors.Phone[index])
          }
          this.campos.phone.error = true
        }
        if (error.response.data.errors.Location) {
          for (let index = 0; index < error.response.data.errors.Location.length; index += 1) {
            this.campos.location.mensajesError.push(error.response.data.errors.Location[index])
          }
          this.campos.location.error = true
        }
        if (error.response.data.errors.Status) {
          for (let index = 0; index < error.response.data.errors.Status.length; index += 1) {
            this.campos.status.mensajesError.push(error.response.data.errors.Status[index])
          }
          this.campos.status.error = true
        }
        if (error.response.data.errors.Gender) {
          for (let index = 0; index < error.response.data.errors.Gender.length; index += 1) {
            this.campos.gender.mensajesError.push(error.response.data.errors.Gender[index])
          }
          this.campos.gender.error = true
        }
        if (error.response.data.errors.DateOfBirth) {
          for (let index = 0; index < error.response.data.errors.DateOfBirth.length; index += 1) {
            this.campos.dateOfBirth.mensajesError.push(error.response.data.errors.DateOfBirth[index])
          }
          this.campos.dateOfBirth.error = true
        }
        if (error.response.data.errors.phone) {
          for (let index = 0; index < error.response.data.errors.phone.length; index += 1) {
            this.campos.phone.mensajesError.push(error.response.data.errors.phone[index])
          }
          this.campos.phone.error = true
        }
        if (error.response.data.errors.MaritalStatus) {
          for (let index = 0; index < error.response.data.errors.MaritalStatus.length; index += 1) {
            this.campos.maritalStatus.mensajesError.push(error.response.data.errors.MaritalStatus[index])
          }
          this.campos.maritalStatus.error = true
        }
        if (error.response.data.errors.AreaId) {
          for (let index = 0; index < error.response.data.errors.AreaId.length; index += 1) {
            this.campos.areaId.mensajesError.push(error.response.data.errors.AreaId[index])
          }
          this.campos.areaId.error = true
        }
        if (error.response.data.errors.Area) {
          for (let index = 0; index < error.response.data.errors.Area.length; index += 1) {
            this.campos.area.mensajesError.push(error.response.data.errors.Area[index])
          }
          this.campos.area.error = true
        }
        this.loadingSaveForm = false
      }
    },
  },
}
</script>
